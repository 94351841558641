.hidden-xs {
  display: none !important;
}
.show-xs {
  display: table-cell !important;
}

@media screen and (min-width: 576px) {
  .hidden-sm {
    display: none !important;
  }
  .show-sm {
    display: table-cell !important;
  }
}

@media screen and (min-width: 768px) {
  .hidden-md {
    display: none !important;
  }
  .show-md {
    display: table-cell !important;
  }
}

@media screen and (min-width: 992px) {
  .hidden-lg {
    display: none !important;
  }
  .show-lg {
    display: table-cell !important;
  }
}

@media screen and (min-width: 1200px) {
  .hidden-xl {
    display: none !important;
  }
  .show-xl {
    display: table-cell !important;
  }
}

@media screen and (min-width: 1600px) {
  .hidden-xxl {
    display: none !important;
  }
  .show-xxl {
    display: table-cell !important;
  }
}
