.success {
  border-color: #ffffff;
  color: #ffffff;
  background-color: #099113;
}
.error {
  border-color: #ffffff;
  color: #ffffff;
  background-color: #f5222d;
}
.authorized {
  border-color: #ffffff;
  color: #ffffff;
  background-color: #435ff7;
}
