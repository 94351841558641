.card {
  background: var(--card-blue);
}
.rightAlign {
  text-align: right;
}
.headerButton {
  margin-top: 10px;
  margin-bottom: 20px;
}
.filterButton {
  margin-bottom: 15px;
}
.filterRow {
  min-height: 80px;
}

@media only screen and (max-width: 768px) {
  .searchTitle {
    text-align: center;
  }
}
