.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imageContainer {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
}

.addItem {
  float: left;
  width: 6.5rem;
  height: 6.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 0.0625rem dashed #d9d9d9;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.addIcon {
  font-size: 2rem;
  color: #999;
}
.addText {
  padding-top: 0.5rem;
  color: #666;
}
