.resultsGrid {
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  grid-gap: 2rem;
}

.paginationTop,
.paginationBottom {
  display: flex;
  justify-content: center;
}
.paginationTop {
  padding: 1rem 1rem 0rem 0rem;
}
