/* Tag Classes*/
.completed {
  background-color: #effff0;
  color: #099113;
  border-color: #099113;
}
.inProgress {
  background-color: #eef1ff;
  color: #4665f3;
  border-color: #4665f3;
}
.cancelled {
  background-color: #ffe1e3;
  color: #f22735;
  border-color: #f22735;
}
.pending {
  background-color: #f9ecff;
  color: #7c10af;
  border-color: #7c10af;
}
