.stepSectionHeader {
  width: auto;
  margin-left: -24px;
  margin-right: -24px;
}
.stepSectionTitle {
  font-weight: 800;
  margin-bottom: 0;
}
.stepSectionDetails {
  font-weight: 600;
  font-size: 14px;
  text-transform: none;
  color: var(--light-gray);
  margin-bottom: 0;
}
.stepSectionContent {
  padding: 40px 3px 60px 3px;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .stepSectionContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }
}
