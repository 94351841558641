.icon {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-style: solid;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  height: 5.625rem;
  width: 5.625rem;
}

.graphic svg {
  min-height: 2.75rem;
  min-width: 3.312rem;
  color: #435ff7;
}
