.headerButtons {
  text-align: center;
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .headerButtons {
    text-align: right;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .headerButtons {
    margin-bottom: 0;
  }
}
