.rightAlign {
  text-align: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: capitalize;
}
.alignBottom {
  margin-top: auto;
}
