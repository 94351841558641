.detailsRow {
  margin-top: 1rem;
}

.detailsInfo {
  padding-left: 1rem;
}

.selectImageContainer {
  padding: 1rem;
}

.selectedImage {
  height: auto;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.versionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
.versionItem {
  width: 12rem;
  height: 100px;
  padding: 0.5rem;
}

.versionImage {
  height: 100%;
  opacity: 0.75;
}

.versionImage.selected {
  opacity: 1;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
}
