.container,
.item {
  width: 14rem;
  height: 14rem;
  margin: 0 0.5rem 0.5rem 0;
}
.container.clickable {
  cursor: pointer;
}

.item {
  padding: 0.25rem;
  border: 1px solid #d9d9d9;
  border-radius: 0.25rem;
  position: relative;
}

.itemInfo {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 0;
  transition: background-color 0.3s;
}
.itemInfo::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: " ";
}

.itemThumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0.5rem;
  left: 0.5rem;
}

.itemImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
