*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Open Sans, Helvetica Neue, Arial, Helvetica, Arial, Free Sans,
    Helvetica, Verdana, Deja Vu Sans, Sans-Serif;
}
