.statCard {
  padding: 0;
}
.statContainer {
  text-align: center;
  padding: 0;
}
.statValue {
  margin: 0;
}
.statCardTitle {
  font-size: 22px;
}
.percentageChange {
  font-size: 22px;
  margin: 0;
}
.positive {
  color: var(--success-green);
}
.negative {
  color: var(--error-red);
}
.notAvailable {
  color: gray;
}
.statCardDivider {
  margin: 15px 0;
}
