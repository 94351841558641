.credit {
  color: #099113;
  border-color: #099113;
  background-color: #effff0;
}
.order {
  color: #ba7c00;
  border-color: #ba7c00;
  background-color: #f0f4ff;
}
.refund {
  color: #390099;
  border-color: #390099;
  background-color: #f0f4ff;
}
.recredit {
  color: #435ff7;
  border-color: #435ff7;
  background-color: #f0f4ff;
}
.spent {
  color: #f5222d;
  border-color: #f5222d;
  background-color: #ffe1e2;
}
.coupon {
  color: #a4036f;
  border-color: #a4036f;
  background-color: #f0f4ff;
}
.redeemed {
  color: #426f42;
  border-color: #426f42;
  background-color: #f0f4ff;
}
.voided {
  color: #f5222d;
  border-color: #f5222d;
  background-color: #ffe1e2;
}
