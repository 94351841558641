.accepted {
  background-color: #effff0;
  border-color: #099113;
  color: #099113;
}
.declined {
  background-color: #ffe1e2;
  border-color: #f5222d;
  color: #f5222d;
}
.open {
  background-color: #f0f4ff;
  border-color: #435ff7;
  color: #435ff7;
}
