.triToggleBackground {
  position: absolute;
  top: 50%;
  margin-top: -3px;
  background: var(--button-blue);
  height: 5px;
  width: 100%;
  border-radius: 5px;
}
.sliderWrapper {
  height: 40px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid var(--button-blue);
  border-radius: 3px;
  position: relative;
}
.triToggleSelection {
  position: relative;
  left: 0;
  width: 33.3%;
  height: 100%;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  background: transparent;
}
.triToggleSelector {
  position: absolute;
  top: 0;
  background: var(--button-blue);
  height: 100%;
  width: 33%;
  cursor: pointer;
}
.triSelectorLeft {
  left: 0px;
  transition: left 0.5s;
}
.triSelectorMiddle {
  left: 33.3%;
  transition: left 0.5s;
}
.triSelectorRight {
  left: 67%;
  transition: left 0.5s;
}
.selected {
  color: white;
  z-index: 1;
}
.labelsContainer {
  display: flex;
  justify-content: space-around;
}
.triToggleWrapper {
  width: 250px;
  margin-right: 30px;
  color: var(--button-blue);
  font-weight: 600;
}
.largeToggle {
  width: 130px;
  height: 36px;
}
/* Duo Toggle CSS */
.duoToggleWrapper {
  width: 15.625rem;
  color: var(--button-blue);
  font-weight: 600;
}
.sliderWrapper {
  height: 40px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid var(--button-blue);
  border-radius: 3px;
  position: relative;
}
.selected {
  color: white;
  z-index: 1;
}
.selection {
  position: relative;
  left: 0;
  width: 50%;
  height: 100%;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  background: transparent;
}
.duoSelectorToggle {
  position: absolute;
  top: 0;
  background: var(--button-blue);
  height: 100%;
  width: 50%;
  cursor: pointer;
}
.selectorLeft {
  left: 0px;
  transition: left 0.5s;
}
.selectorRight {
  left: 50%;
  transition: left 0.5s;
}
