.dinein {
  border-color: #435ff7;
  color: #435ff7;
  background-color: transparent;
}
.pickup {
  border-color: #9a0000;
  color: #9a0000;
  background-color: transparent;
}
.delivery {
  border-color: #390099;
  color: #390099;
  background-color: transparent;
}

.kiosk {
  border-color: #bc5e00;
  color: #bc5e00;
  background-color: transparent;
}
