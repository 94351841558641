.button {
  font-family: Open Sans, Helvetica Neue, sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-align: center;
  font-size: 15px;
  height: 40px;
  width: 100%;
  border-radius: 3px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
}
.blue {
  background: var(--button-blue);
  border: 1px solid var(--button-blue);
  color: #fff;
}
.yellow {
  border: 1px solid #c2b416;
  background: #feed01;
  color: #3d3d3d;
  text-transform: capitalize;
}
.white {
  background: white;
  border: white;
  color: var(--button-blue);
}
.transparent {
  background: transparent;
  color: var(--button-blue);
  border: 1px solid var(--button-blue);
}
.transparent:hover {
  background: var(--button-blue);
  color: white;
  border: 1px solid var(--button-blue);
}
.extraSmall {
  width: 100px;
}
.small {
  width: 150px;
}
.medium {
  width: 200px;
}
.large {
  width: 400px;
}
.transparentRed {
  border: 1px solid #f5222d;
  color: #f5222d;
  background: white;
}
.transparentRed:hover {
  border: 1px solid #f5222d;
  color: white;
  background: #f5222d;
}
.green {
  border: 1px solid #099113;
  color: white;
  background: #099113;
}
.green:focus {
  border: 1px solid #099113;
  color: white;
  background: #099113;
}
.green:hover {
  border: 1px solid #099113;
  color: #099113;
  background: white;
}
.red {
  background: #f5222d;
  border: 1px solid #f5222d;
  color: #fff;
}
.red:focus {
  background: #f5222d;
  border: 1px solid #f5222d;
  color: #fff;
}
.red:hover {
  color: #f5222d;
  border: 1px solid #f5222d;
  background: #fff;
}
.marginLeft {
  margin-left: 5px;
}
.borderless {
  border: none;
}
.floatRight {
  float: right;
}
.floatLeft {
  float: left;
}
