.detailsContainer {
  display: flex;
  align-items: flex-start;
}

.imagePreview,
.infoPreview {
  display: inline-block;
}

.imagePreview {
  flex-grow: 0;
}

.infoPreview {
  flex-grow: 1;
}

.detailsRow {
  display: flex;
}
