.positionLabel {
  text-align: center;
}

.container,
.item {
  float: left;
  width: 6.5rem;
  height: 6.5rem;
  margin: 0 0.5rem 0.5rem 0;
}

.item {
  padding: 0.5rem;
  border: 0.0625rem solid #d9d9d9;
  border-radius: 0.25rem;
  position: relative;
  cursor: -webkit-grab;
  cursor: grab;
}

.itemInfo {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 0;
  transition: background-color 0.3s;
}
.itemInfo::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: " ";
}

.itemInfo:hover::before,
.itemInfo:hover + .itemActions {
  opacity: 1;
}

.itemThumbnail {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;

  top: 0.5rem;
  left: 0.5rem;
  font-size: 1.625rem;
  line-height: 3.375rem;
  text-align: center;
  opacity: 0.8;
}

.itemImage {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.itemName {
  display: none;
  margin: 0.5rem 0 0;
  padding: 0;
  line-height: 1.5;
  text-align: center;
}

.itemActions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.itemActions:hover {
  opacity: 1;
}
.iconColor {
  color: white;
}
