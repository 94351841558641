.dateDropdown {
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
}
.dateIntervals {
  border-bottom: 1px solid var(--field-border-blue);
}
.intervalItems {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  color: var(--main-blue);
}
.customRangeTitle {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  color: var(--main-blue);
}
.buttonWrapper {
  width: 90%;
  display: inline-flex;
  align-items: center;
  height: 40px;
  border: 1px solid var(--button-blue);
  border-radius: 3px;
}
.decrementButton {
  height: 100%;
  display: inline-flex;
  padding-left: 5px;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  width: 15%;
  border-right: 1px solid var(--button-blue);
  color: var(--button-blue);
  transition: width 1s;
}
.incrementButton {
  height: 100%;
  display: inline-flex;
  padding-right: 5px;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  width: 15%;
  color: var(--button-blue);
  border-left: 1px solid var(--button-blue);
  color: var(--button-blue);
}
.dateButton {
  width: 90%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: var(--button-blue);
}
.hideDecrementButton {
  display: none;
}
.hideIncrementButton {
  display: none;
}
.hideDateButton {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: var(--button-blue);
}
.dropdownArrow {
  position: absolute;
  right: 0;
  padding: 0 15px;
}
