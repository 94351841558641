.logoContainer {
  padding: 15px 20px;
  height: 84px;
  width: 100%;
  display: inline-block;
}
.logoImg {
  width: 100%;
  height: auto;
  display: block;
}
