.right {
  text-align: right;
  margin-left: auto;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.inline {
  display: inline;
}
