.orderTypeTag {
  margin: 0;
}
.itemPrice {
  float: right;
}
.modItemPrice {
  margin-left: auto;
}
.rightAlignButtons {
  float: right;
  margin-left: 5px;
  margin-top: 5px;
}
.orderItemsHeader {
  background-color: var(--button-blue);
  height: 50px;
  padding: 0 10px 0 10px;
  border-radius: 4px 4px 0 0;
}
.orderItemsHeaderText {
  margin-top: 14px;
}
.marginLeft {
  margin-left: auto;
}
.orderItemPrice {
  float: right;
}
.orderListItem {
  display: block;
}
.itemDescription {
  width: 75%;
  overflow-wrap: normal;
}
.itemDetails {
  color: black;
  margin-top: -9px;
  margin-bottom: 12px;
}
.itemNotes {
  padding: 10px 0;
}
.itemNotesLabel {
  font-weight: 600;
}
.nestedModifications {
  margin-left: 20px;
}
.nestedModifications li[class*="ant-list-item"] {
  border-bottom: none;
}
