.title {
  margin: 0;
  padding: 0;
}
.chartTitle {
  margin-bottom: 0;
  margin-top: 10px;
}
.filters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.filterButton {
  margin-left: 20px;
}
.headerWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.tooltip {
  font-weight: 600;
}
.datePicker {
  width: 250px;
  margin-left: 1.25rem;
}

.triToggle {
  margin-left: auto;
  margin-right: 0px;
}

.triToggleSubscriptionAnalytics {
  margin-bottom: 1.25rem;
}

@media only screen and (max-width: 992px) {
  .filters {
    margin-top: 20px;
  }
  .headerWrapper {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 576px) {
  .breakdownTitle {
    text-align: center;
  }
  .headerWrapper {
    text-align: center;
  }
  .filterButton {
    margin-left: 0;
    width: 100%;
    margin-top: 10px;
  }
  .datePicker {
    width: 100%;
  }
  .triToggle {
    width: 100%;
    margin-right: 0;
  }
}
