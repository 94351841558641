.rightAlignButtons {
  float: right;
  margin-left: 5px;
  margin-top: 5px;
}
.paymentHeader {
  background-color: var(--button-blue);
  height: 50px;
  padding: 0 10px 0 10px;
  border-radius: 4px 4px 0 0;
}
.paymentHeaderText {
  margin-top: 14px;
}
.paymentHeaderButtons {
  margin-top: 8px;
}
.paymentRow {
  color: var(--main-blue);
}
.refundButton {
  margin-top: 5px;
}
.paymentSummaryTitle {
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 20px;
  display: inline;
}
.issueRefund {
  margin-top: 11px;
  text-transform: uppercase;
}
.processRefundButton {
  float: right;
  margin-bottom: 55px;
}
.refundedPaymentsRow {
  color: #f5222d;
}
.refundedInput {
  margin-top: 10px;
  float: right;
}
.paymentDetailFields {
  padding-left: 20px;
}
