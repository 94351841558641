.ExpandableTable {
  cursor: pointer;
}
.selectedExpandIcon {
  transform: rotate(90deg);
  transition: transform 0.25s;
}
.expandIcon {
  transition: transform 0.25s;
}
