.rightAlign {
  text-align: right;
}
.buttonWrapper {
  display: inline-flex;
  justify-content: flex-end;
  padding-left: 20px;
  width: 100%;
}
.radiusInput {
  flex-shrink: 0;
  margin-right: 20px;
}
.radiusWrapper {
  display: flex;
  align-items: center;
}
.saveButton {
  margin-left: 20px;
}
.drawClearButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
}
.radiusButton {
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
  width: 45%;
}
