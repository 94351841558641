.textSmall {
  width: 200px;
}
.border {
  border: 1px solid #979797;
}
.numberSmall {
  width: 100px;
}

/* INPUT */
.input {
  background: transparent;
  width: 100%;
  &:hover,
  &:focus {
    border-width: 0 0 1px 0 !important;
  }
  &::placeholder {
    color: transparent;
  }
  &.placeholder::placeholder {
    color: lightgray;
  }
}

/* EDITABLE TEXT */
.titleMargin {
  margin: 10px 0px 25px 0px;
}
.editMargin {
  margin: 5px 0px 25px 13px;
}

/* CALENDAR */
.calendarWrapper {
  width: 300px;
}
.calendarLabel {
  font-weight: 600;
  text-transform: uppercase;
  color: var(--main-blue);
  margin-bottom: 15px;
}
.calenderHeader {
  font-style: italic;
  color: var(--button-blue);
}

/* LIVE SEARCH */
.searchResults {
  max-height: 200px;
  display: none;
}
.visible {
  display: initial;
}
.searchResult {
  padding: 5px;
  margin-bottom: 3px;
  cursor: pointer;
  border-radius: 3px;
}
.searchContainer {
  width: 100%;
  position: relative;
}
.searchDropdown {
  width: 100%;
  z-index: 3;
  left: 15px;
}
.searchDropdown input:focus + .search-results {
  display: block;
}

/* DATE RANGE */
.endDatePicker {
  margin-left: 1.25rem;
}
.startFieldWrapper {
  margin-bottom: 0;
  display: inline-block;
}
.endFieldWrapper {
  display: inline-block;
}
.errorText {
  color: red;
}
@media only screen and (max-width: 576px) {
  .startFieldWrapper {
    margin-bottom: 1.875rem;
  }
}

/* TIME RANGE */
.timeRangePicker {
  width: 100%;
  min-width: 110px;
}

.liveSearchWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.liveSearch {
  background: transparent;
  border-width: 0 0 0.0625rem 0;
  border-color: var(--field-border-blue);
  padding: 0.25rem 0.75rem;
  border-style: solid;
  width: 100%;

  &:focus {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(24, 144, 255);
  }
}

.liveSearchOptions {
  position: absolute;
  z-index: 100;
  display: none;
  opacity: 0;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 5px 0px rgba(214, 214, 214, 1);
}

.liveSearchOptionsVisible {
  opacity: 1;
  display: block;
}

.liveSearchOptionsFetching {
  height: 50px;
}

.liveSearchOption {
  padding: 0.3125rem;
  &:hover {
    background-color: lightgray;
  }
  span {
    visibility: inherit;
    font-weight: 500;
    font-size: 0.875rem;
    color: black;
  }
}
