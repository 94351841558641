.right {
  float: right;
  margin-left: auto;
}
.left {
  float: left;
}
.center {
  text-align: center;
}
