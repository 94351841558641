.striped tbody tr:nth-child(odd) {
  background-color: #fafafa;
}
.error {
  background-color: #ffecec !important;
}
.click {
  cursor: pointer;
}
.row {
  font-weight: 600;
  color: var(--main-blue);
  height: 55px;
}
.table {
  background: white;
}
.rightAlign {
  text-align: right;
}
.inStock {
  border-color: #435ff7;
  color: #435ff7;
  background-color: #f0f4ff;
}
.outOfStock {
  border-color: #f5222d;
  color: #f5222d;
  background-color: #ffe1e2;
}
