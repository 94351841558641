.fallback {
  margin-left: auto;
  margin-right: auto;
  width: 125px;
  position: relative;
}

.fallbackText {
  text-align: center;
  color: lightGray;
}
