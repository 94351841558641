.header {
  display: flex;
  align-items: center;
}
.navHeader {
  width: 42%;
  max-width: 250px;
  min-width: 100px;
  margin-right: 50px;
}
.zendeskDisplay {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  height: 50px;
}

.info-icon {
  color: var(--button-blue);
}
.scrollToTopContainer {
  margin-right: 1rem;
  bottom: 19px;
  right: 155px;
  width: 2.8125rem;
}

@media only screen and (max-width: 480px) {
  .scrollToTopContainer {
    height: 3rem;
    bottom: 10px;
    right: 64px;
  }
}

@media only screen and (min-width: 600px) {
  .locationLabel {
    display: none;
  }
  .navHeader {
    margin-right: 5px;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .locationLabel {
    display: none;
  }
  .navHeader {
    margin-right: 5px;
    margin-left: 5px;
  }
}

/* New Header Nav */

.icon {
  color: #a3a3a3;
  vertical-align: middle;
  padding-right: 15px;
  font-size: 20px;
}

.goForward {
  text-align: left;
  color: #3b989c;
  cursor: pointer;
  margin-left: auto;
}
