.title {
  font-weight: 600;
  letter-spacing: 0.32px;
  color: #424242;
  margin-top: 0;
  margin-bottom: 1em;
}
.sectionTitle {
  color: var(--main-blue);
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
  letter-spacing: 0.2px;
}
.cardTitle {
  color: var(--main-blue);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.2px;
  margin-bottom: 1.5rem;
}
.blueCardTitle {
  padding-top: 0.625rem;
  color: var(--button-blue);
  font-weight: 800;
  font-size: 1.75rem;
  text-transform: uppercase;
}
.editableTitle {
  color: black;
  font-family: var(--font-1);
  font-weight: 600;
  font-size: 1.5rem;
}
.errorText {
  color: var(--error-red);
}
