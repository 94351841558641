:root {
  --main-blue: #3a3c6b;
  --nav-blue: #0c0e40;
  --field-border-blue: #7678ad;
  --light-select-blue: #108dff;
  --button-blue: #435ff7;
  --nav-text: #7678ad;
  --card-blue: #f0f4ff;
  --login-background: #f5f5f5;
  --ant-blue: #1890ff;
  --success-green: #099113;
  --error-red: #f5222d;
  --box-shadow: #00000026;
  --warning-yellow: #ffcc00;
  --font-1: Open Sans, sans-serif;
}

.fa-fw {
  width: auto !important;
}

input,
button {
  background-color: transparent;
}

.ant-spin-dot-item {
  background-color: var(--button-blue);
}
.ant-spin-text {
  color: var(--button-blue);
}

.ant-layout-sider {
  position: fixed;
  z-index: 4;
  height: 100%;
  background: var(--nav-blue);
}

.ant-layout-sider-children {
  // overflow-y: scroll;
  overflow-x: hidden;
}

.ant-layout {
  background: #fff;
}

.ant-row {
  width: 100%;
}

.ant-layout-header {
  height: 3.125rem;
  line-height: 3rem;
  background-color: #fff;
  padding: 0rem 0rem 0rem 1rem;
  text-align: right;

  > * {
    vertical-align: middle;
    margin-right: 1rem;
  }

  .ant-select-selection-selected-value {
    font-family: var(--font-1);
  }
}

.ant-menu {
  background: var(--nav-blue);
  border: none;
}

.ant-menu-item {
  font-weight: 600;
  color: var(--nav-text);
  > a {
    font-weight: 600;
    color: var(--nav-text);
  }
}

.ant-menu-item-active {
  &:not(.ant-menu-item-selected) {
    svg {
      fill: var(--ant-blue);
    }
  }
}

.ant-menu {
  &:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      background-color: transparent;
      a {
        color: #fff;
        .customNavIcon {
          fill: #fff;
        }
      }

      &:after {
        display: none;
      }
    }
  }
}

.ant-typography {
  color: var(--main-blue);

  code {
    background: var(--nav-text);
  }
}

.ant-menu-submenu-title {
  font-weight: 600;
  color: var(--nav-text);
}

.ant-menu-submenu {
  > .ant-menu {
    background: var(--nav-blue);
  }
}

.location-nav-text {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.ant-typography-edit {
  font-size: 18px;
}

.ant-form-item {
  margin-bottom: 7px;
}

.ant-form-item-label {
  text-align: center;
  line-height: 1.25rem;

  > label::after {
    display: none;
  }
}

.ant-table-thead {
  background: var(--button-blue);
  > tr {
    > th {
      color: #fff;
      background: var(--button-blue);
      font-weight: 600;
    }
  }
}

.ant-table-content {
  .ant-table-header.ant-table-hide-scrollbar {
    background-color: var(--button-blue);
  }
}

.ant-table-small {
  border: initial;
  > .ant-table-content {
    > .ant-table-body {
      margin: 0;
    }
  }
}

.ant-table-header-column {
  padding: 0.375rem 0.9375rem;
}

th {
  &:not(.ant-table-selection-column) {
    .ant-table-header-column {
      padding-left: 0;
    }
  }
}

.locationToggleTable {
  .ant-table-selection-column {
    width: 65px;
  }
}

.ant-table-column-title {
  color: #fff;
}

.ant-form-item-control {
  margin-bottom: 7px;
}

.ant-table-body {
  &::-webkit-scrollbar {
    -webkit-appearance: none;

    &:vertical {
      width: 11px;
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    border: 0.125rem solid #fff;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.ant-pagination-item-active {
  border-color: var(--button-blue);

  a {
    color: var(--button-blue);
  }
}

.anticon {
  vertical-align: baseline;
}

.anticon.anticon-caret-up.ant-table-column-sorter-up.off {
  svg {
    color: var(--nav-blue);
  }
}

.anticon.anticon-caret-up.ant-table-column-sorter-up.on {
  svg {
    color: #fff;
  }
}

.anticon.anticon-caret-down.ant-table-column-sorter-down.off {
  svg {
    color: var(--nav-blue);
  }
}

.anticon.anticon-caret-down.ant-table-column-sorter-down.on {
  svg {
    color: #fff;
  }
}

.sales-reports-table {
  th:not(:first-child) {
    .ant-table-header-column {
      float: right;
      padding: 0.375rem 0rem 0.375rem 0.9375rem;
    }
  }
}

.subscription-analytics-table {
  tbody {
    tr:last-of-type {
      background: #ffffe0;
    }
  }
}

.expandableIntegrationsTable .ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
  width: 5px;
  min-width: 0rem;
}

tr.drop-over-downward {
  td {
    border-bottom: 0.125rem dashed #1890ff;
  }
}

tr.drop-over-upward {
  td {
    border-top: 0.125rem dashed #1890ff;
  }
}

input.ant-input {
  background: transparent;
  border-radius: 0rem;
  border-width: 0 0 0.0625rem 0;
  border-color: var(--field-border-blue);

  &:hover {
    border-right: none;
  }

  &:focus {
    border-right: none;
  }
}

.ant-calendar-picker-input.ant-input-sm {
  height: 2.1875rem;
  border-radius: 3px;
  border: 0.0625rem solid #979797;
}

.ant-calendar-picker {
  min-width: 110px !important;
}

.ant-calendar-range-picker-separator {
  vertical-align: initial;
}

.ant-input-number {
  border-radius: 0rem;
  border-width: 0 0 0.0625rem 0;
  border-color: var(--field-border-blue);
  background: transparent;

  &:hover {
    border-right: none;
  }
}

.ant-input-number-focused {
  border-right: none;
}

.refundInput {
  .ant-input-number-handler-wrap {
    display: none;
  }

  input {
    text-align: right;
    padding: 0;
  }
}

.ant-select-sm.ant-select.ant-select-enabled {
  border-radius: 3px;
}

.ant-select-selection {
  border: 0.0625rem solid #979797;
  background: transparent;
  border-radius: 0rem;
  border-width: 0 0 0.0625rem 0;
  border-color: var(--field-border-blue);
  border-style: solid;

  &:hover {
    border-width: 0 0 0.0625rem 0 !important;
  }

  &:focus {
    border-width: 0 0 0.0625rem 0 !important;
  }
}

.ant-modal-close {
  display: none;
}

.ant-btn-danger.ant-btn-icon-only {
  height: 2.1875rem;
  background-color: #ec644b;
  border-color: #fff;

  &:hover {
    background-color: #f1a9a0;
  }

  i {
    color: #fff;
  }
}

.ant-select-lg.ant-select.ant-select-enabled {
  width: 120px;
  font-size: 0.75rem;
}

.ant-fullcalendar-calendar-body {
  padding: 0.9375rem 0 0 0;

  thead {
    tr {
      background-color: var(--button-blue);
      height: 3.125rem;
      overflow: hidden;

      &:first-child {
        th:last-child {
          border-top-right-radius: 0.5rem;
        }

        th:first-child {
          border-top-left-radius: 0.5rem;
        }
      }
    }
  }
}

.ant-select-selection--multiple {
  border-right: none;
}

.has-error {
  .ant-select-selection {
    border-right: none;
  }
}

.stateField {
  .ant-select-selection__rendered {
    line-height: 39px;
  }
}

.ant-calendar-picker-input.ant-input.ant-input-sm {
  background-color: transparent;
  border-color: var(--field-border-blue);
}

.dateDropdown {
  .ant-calendar-picker-input.ant-input.ant-input-sm {
    border: none;
    text-align: center;
  }

  .ant-time-picker {
    .ant-time-picker-input {
      text-align: center;
      border: none;
    }
  }
}

.hoursTable {
  .ant-time-picker-input {
    background: transparent;
    border-radius: 0rem;
    border-width: 0 0 0.0625rem 0;
    border-color: var(--button-blue);
    color: var(--button-blue);
    border-style: solid;
  }

  .ant-time-picker.ant-time-picker-small
    .ant-time-picker-icon
    .anticon-clock-circle {
    color: var(--button-blue);
  }
}

.ant-time-picker-input {
  background: transparent;
  border-radius: 0rem;
  border-width: 0 0 0.0625rem 0;
  border-color: var(--field-border-blue);

  &:hover {
    border-right: none;
  }
}

.ant-upload-list-picture-card {
  .ant-upload-list-item {
    float: left;
    width: 6.5rem;
    height: 6.5rem;
    margin: 0 0.5rem 0.5rem 0;
  }
}

.ant-upload-list {
  float: left;
}

.ant-list-vertical {
  .ant-list-item-extra {
    margin-left: 5px;
  }
}

.largeToggle.ant-switch::after {
  height: 2rem;
  width: 2rem;
}

.largeToggle {
  width: 130px;
  height: 36px;

  .ant-switch-inner {
    line-height: 1.2;
  }
}

.ant-switch-checked {
  background-color: var(--button-blue);
}

.locationStatusSwitch {
  .ant-switch {
    .ant-switch-inner {
      font-size: 11px;
    }
  }
}

.ant-collapse.ant-collapse-borderless {
  background: transparent;

  .ant-collapse-item {
    border-bottom: none;
  }

  .ant-collapse-header {
    font-weight: 600;
    line-height: 0.625rem;
    text-transform: uppercase;
    color: var(--main-blue);
    font-size: 0.9375rem;
    padding-left: 1.25rem;

    .ant-collapse-arrow {
      left: 0;
    }
  }
}

.pickupTimes {
  .ant-card-body {
    padding-right: 0.78125rem;
  }
}

.deliveryTimes {
  .ant-card-body {
    padding-left: 0.78125rem;
  }
}

.dineInTimes .ant-card-body {
  padding-right: 0.78125rem;
}

.groups .ant-card-body {
  padding-right: 0.78125rem;
}

.subgroups .ant-card-body {
  padding-left: 0.78125rem;
}

.paymentSummary .ant-card-body {
  padding-right: 0.78125rem;
}

.orderItems .ant-card-body {
  padding-left: 0.78125rem;
}

.ant-tabs-nav {
  .ant-tabs-tab-active {
    color: var(--button-blue);
  }

  .ant-tabs-tab:hover {
    color: var(--button-blue);
  }
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background-color: var(--button-blue);
}

.ant-spin.ant-spin-spinning.suspenseFallback {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.analyticsDatePickers .ant-calendar-picker-input.ant-input.ant-input-sm {
  width: 50%;
}

.headerFilters .ant-calendar-picker-input {
  text-align: left;
}

.graph text {
  fill: var(--main-blue) !important;
  font-weight: 400;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--button-blue);
  border-color: var(--button-blue);
}

.ant-checkbox-inner::after {
  left: 25%;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  background: var(--card-blue);
}

.noResultsIcon {
  width: 7.8125rem;
  height: auto;
}

.ant-fullcalendar-table {
  font-family: var(--font-1);
  font-size: 0.875rem;
  color: #fff;
  font-weight: 600;
  border-collapse: collapse;

  th,
  td {
    border-right: 0.0625rem solid var(--button-blue);
    border-left: 0.0625rem solid var(--button-blue);
  }

  th,
  thead td {
    border-left: 0;
    border-right: 0;
  }

  tr:last-child {
    border-bottom: 0.0625rem solid var(--button-blue);
  }
}

.ant-fullcalendar-value {
  color: var(--main-blue);
  font-family: var(--font-1);
  font-size: 0.875rem;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .ant-layout-sider-zero-width-trigger {
    z-index: 1;
    box-shadow: 0.125rem 0 0.5rem var(--box-shadow);
    top: 5px !important;
    height: 2.1875rem !important;
  }
  .ant-card-body {
    padding: 0.9375rem;
  }

  .hoursTable .ant-time-picker {
    .ant-time-picker-input {
      width: 80px;
      font-size: 0.75rem;
    }

    .ant-time-picker-icon svg {
      width: 0.75rem;
    }
  }

  .ant-time-picker {
    width: 80px;

    .ant-time-picker-input::placeholder {
      font-size: 0.625rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .ant-select-lg.ant-select.ant-select-enabled {
    width: 210px;
    font-size: 1rem;
  }
  .ant-layout-sider {
    position: relative;
    z-index: 1;
    height: inherit;
  }
  .ant-layout-header {
    text-align: initial;
  }
}
