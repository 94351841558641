.tag {
  padding: 2px 10px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  display: inline-block;
  font-size: 12px;
  text-align: center;
  margin-right: 8px;
}
