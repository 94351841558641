nav[aria-label="Breadcrumbs"] a {
  color: #737373;
}

h1 {
  margin-bottom: 0;
}

form input[type="radio"].h-6 {
  height: 1.5rem;
}

a[disabled] {
  color: #737373;
  pointer-events: none;
  text-decoration: none;
}

a:hover {
  color: #07b8ba;
}
