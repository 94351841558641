/* Month Picker */
.buttonWrapper {
  width: 18.75rem;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom;
  height: 2.5rem;
  border: 1px solid var(--button-blue);
  border-radius: 3px;
  margin-right: 0.3125rem;
}
.decrementButton,
.incrementButton,
.dateButton {
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  width: 15%;
  color: var(--button-blue);
}

.decrementButton {
  padding-left: 0.3125rem;
  border-right: 1px solid var(--button-blue);
  transition: width 1s;
}
.incrementButton {
  padding-right: 0.3125rem;
  border-left: 1px solid var(--button-blue);
}

.dateButton {
  width: 90%;
  font-weight: 600;
  font-size: 0.875rem;
}

.dateDropdown {
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
}

.dateIntervals {
  border-bottom: 1px solid var(--field-border-blue);
}
.intervalItems {
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  color: var(--main-blue);
}
