.available {
  width: 95px;
  background-color: #effff0;
  color: #099113;
  border-color: #099113;
}
.unavailable {
  width: 95px;
  background-color: #ffe1e2;
  color: #f5222d;
  border-color: #f5222d;
}
