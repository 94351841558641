.customNavIcon {
  width: 14px;
  height: 14px;
  fill: var(--nav-text);
  vertical-align: middle;
  display: initial;
}

.text {
  margin-left: 10px;
}
